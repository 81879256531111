@import url(https://fonts.googleapis.com/css?family=Roboto%20Mono);
/* * {
    font-family: 'Roboto Mono', sans-serif;
} */

#pdf-controls {
    display: none;
}

#pdf-page-wrapper {
    margin: 0px !important;
}
  
#keywordSearch::-moz-placeholder {
    color:black;
    opacity: 1.8;
  }
  
#keywordSearch::placeholder {
    color:black;
    opacity: 1.8;
  }
